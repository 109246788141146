import React from 'react';
// import Layout from '../components/layout.js';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Collection from '../components/collection.js';

const WorksPage = ({ pageContext}) => {
  let {
    allSerials,
    allStories
  } = pageContext;

  const bannerData = useStaticQuery(graphql`
  query {
    serials: file(relativePath: { eq: "serials-banner.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    stories: file(relativePath: { eq: "stories-banner.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
  }
`)
  let serialsBanner = bannerData.serials.childImageSharp.fluid;
  let storiesBanner = bannerData.stories.childImageSharp.fluid;

  return (
      <section id='works' className="w-full mb-8">
      <Img fluid={serialsBanner} id='serials'alt='handdrawn banner saying serials' className='w-1/3 m-auto mb-8 mt-16'/>
      <Collection works={allSerials}/>
      <Img fluid={storiesBanner} id='stories' alt='handdrawn banner saying serials' className='w-1/3 m-auto mt-16 mb-8'/>
      <Collection works={allStories}/>
      </section>
  )
}

export default WorksPage;
